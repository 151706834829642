<template>
    <div id="VisitFormContainer">
        <b-container v-if="!ot_number">
            <b-row>
                <b-col cols="12">
                    <h1>Error</h1>
                    <p>Este formulario no debe ser llamado directamente ni actualizado.</p>
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid v-if="ot_number">
            <b-row>
                <b-col cols="12">
                    <h3>Generar visita técnica {{ text }}</h3>
                    <p>{{ $route.params.nombre }}</p>
                </b-col>
            </b-row>
            <b-row v-if="order">
                <b-col md="12" lg="6">
                    <b-overlay :show="showOverlay" rounded="sm">
                    <b-alert v-if="order.visits_pending > 0" show variant="warning">Esta OT tiene {{ order.visits_pending > 1 ? "visitas abiertas" : "una visita abierta" }} ({{ order.visits_pending  }}), <a href="#">{{ order.visits_pending > 1 ? "consultarlas" : "consultarla" }}</a></b-alert>
                    <b-alert v-if="!order.possible_timestripes.length" show variant="danger">Esta operación no tiene franjas horarias configuradas. <a href="#">Configurar ahora</a></b-alert>
                    <p>Referente a la OT: <b>{{ $route.params.ot_number}}</b></p>
                    <b-form v-if="show" @submit="onSubmit" @reset="onReset">
                        <!-- <b-form-group id="input-group-2" label="Prioridad:" label-for="input-2">
                            <b-form-input
                            id="input-2"
                            v-model="form.priority"
                            type="number"
                            placeholder="Prioridad"
                            required
                            max="50"
                            min="0"
                            @input="checkPriorityValue"
                            maxlength = "2"
                            ></b-form-input>
                        </b-form-group> -->

                        <b-form-group id="input-group-21" label="Notas al técnico:" label-for="input-21">
                             <b-form-textarea
                                id="textarea"
                                v-model="form.tech_comments"
                                placeholder="Escriba su texto..."
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>
                        </b-form-group>

                        <b-form-group id="input-group-38" label="Zona:" label-for="input-38">
                            <b-form-select
                            id="input-38"
                            name="input-38"
                            v-model="form.zone"
                            :options="zones"
                            value-field="id"
                            text-field="name"
                            >
                            <template #first>
                                <b-form-select-option :value="null">Sin zona asignada</b-form-select-option>
                            </template>
                            </b-form-select>
                        </b-form-group>

                        <b-form-group id="input-group-31" label="Fecha tentativa:" label-for="input-31">
                            <b-form-datepicker
                                id="input-31" 
                                v-model="form.possibleDate"
                                :min="new Date()"
                                :date-disabled-fn="dateDisabled"
                                v-bind="calendar_labels || {}"
                                :locale="calendar_locale"
                                :dropup="true"
                                @input="getAvailableTimestripes"
                                required>
                            </b-form-datepicker>
                        </b-form-group>

                        <b-form-group id="input-group-4" label="Franja horaria:" label-for="input-4">
                            <b-form-select
                            id="input-4"
                            v-model="form.timestripe"
                            :options="order.possible_timestripes"
                            required
                            @change="askIfTimestripeIsPossible()"
                            ></b-form-select>
                        </b-form-group>

                        <b-form-group id="input-group-3" label="Técnico:" label-for="input-3">
                            <b-form-select
                            id="input-3"
                            name="input-3"
                            v-model="form.assigned_to"
                            :options="technicians_list"
                            >
                            <template #first>
                                <b-form-select-option :value="null">&nbsp;</b-form-select-option>
                            </template>
                            </b-form-select>

                        </b-form-group>

                        <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                            v-model="form.checked"
                            id="checkboxes-4"
                            :aria-describedby="ariaDescribedby"
                            >
                            <!-- <b-form-checkbox value="notify_client" switch disabled>Enviar notificación al cliente</b-form-checkbox> -->
                            <!-- <b-form-checkbox value="public_link" switch>Generar link público</b-form-checkbox> -->
                            </b-form-checkbox-group>
                        </b-form-group>

                        <b-button type="submit" class="mr-2" variant="primary">Crear visita</b-button>
                    </b-form>
                    </b-overlay>
                </b-col>
                <b-col md="12" lg="6">
                    <h4 class="mt-3">Información adicional</h4>
                    <OrderInfo v-if="order" :ot_type="ot_type" :ot_number="ot_number" :order_data="order" :autoload="false"></OrderInfo>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import store from '../store'
import OrderInfo from '../components/OrderInfo.vue'
import axios from 'axios'
import { triggerSegmentUpdate } from '../common'

export default {
    name: "VisitForm",
    store: store,
    props: ['text'],
    data() {
        return {
            ot_type: null,
            ot_number: null,
            order: null,
            operation_assigned: null,
            technicians_list: [],
            axios_error: '',
            show: true,
            zones: null,
            visits_pending: false,
            showOverlay: false,
            form: {
                ot_type: null,
                ot_number: null,
                client_id: null,
                symptom_id: null,
                symptom_name: null,
                priority: 0,
                zone: null,
                node_name: null,
                visit_type: 0,
                tech_comments: '',
                email: '',
                name: '',
                possibleDate: this.changeTimezone(),
                assigned_to: null,
                assigned_name: '',
                timestripe: null,
                late_schedule: false,
                related_orders: null,
                tag: null,
                checked: [
                    'public_link'
                ],
            },
            calendar_locale: "es-AR",
            calendar_labels: {
                labelPrevDecade: 'Década anterior',
                labelPrevYear: 'Año anterior',
                labelPrevMonth: 'Mes anterior',
                labelCurrentMonth: 'Mes actual',
                labelNextMonth: 'Siguiente mes',
                labelNextYear: 'Siguiente año',
                labelNextDecade: 'Siguiente década',
                labelToday: 'Hoy',
                labelSelected: 'Fecha seleccionada',
                labelNoDateSelected: 'Sin fecha seleccionada',
                labelCalendar: 'Calendario',
                labelNav: 'Navegación',
                labelHelp: 'Se puede navegar el calendario con las flechas del teclado'
            }
        }
    },
    components: {
        OrderInfo
    },
    watch: {
        $route(to, from) {
            from
            this.ot_type = to.params.ot_type
            this.ot_number = to.params.ot_number
            this.form.ot_type = to.params.ot_type
            this.form.ot_number = to.params.ot_number
            this.loadOT()
        },
    },
    created: function() {
        this.ot_type = this.$route.params.ot_type
        this.ot_number = this.$route.params.ot_number
        this.form.ot_type = this.$route.params.ot_type
        this.form.ot_number = this.$route.params.ot_number
        this.loadOT()
    },
    methods: {
        changeTimezone: function() {

        var date = new Date()
        date.setTime(date.getTime() - (3*60*60*1000))
        
        // Nos quedamos solo con la fecha, eliminamos la hora
        return date.toISOString() .split("T")[0]

        },
        askIfTimestripeIsPossible: function()
        {
            this.form.late_schedule = false;
            //api_timestripes_eval
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'timestripe_id': this.form.timestripe,
                'date': this.form.possibleDate,
            }

            axios.post(this.$store.getters.getApiURL('api_timestripes_eval') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    if (response.data.data == true)
                    {
                        this.$swal({ 
                        icon: 'warning',
                        title: 'Atención',
                        text: "Seleccionó una franja pasada su hora de finalización"});
                        this.form.late_schedule = true;
                    } else {
                        this.form.late_schedule = false;
                    }

                    // Actualizo el listado de tecnicos, mostrando cuantas visitas tiene asignadas para el dia y el turno  seleccionado
                    this.get_tech_list(this.operation_assigned, this.form.possibleDate, this.form.timestripe) 
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        checkPriorityValue() {

            if (this.form.priority.length > 2) this.form.priority = this.form.priority.slice(0, 2);
        },
        dateDisabled(ymd, date) {
            // Disable weekends (Sunday = `0`, Saturday = `6`) and
            // disable days that fall on the 13th of the month
            ymd
            const weekday = date.getDay()
            console.log(weekday)
            //const day = date.getDate()
            // Return `true` if the date should be disabled
            // || day === 13 || weekday === 6 
            return false
        },
        getAvailableTimestripes: function() {

            // Actualizo el listado de tecnicos, mostrando cuantas visitas tiene asignadas para el dia y el turno  seleccionado
            this.get_tech_list(this.operation_assigned, this.form.possibleDate, this.form.timestripe) 


            //api_visit_get_timestripes_by_visit_id
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'subsidiary': this.operation_assigned,
                'date_selected': this.form.possibleDate
            }

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_visit_get_timestripes_by_date') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.order.possible_timestripes = response.data.data
                    if (this.form.timestripe != null)
                    {
                        this.askIfTimestripeIsPossible();
                    }
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        getZones: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'operations': this.operation_assigned,
                'zone_id': this.order.zone_id
            }

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_zones') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.zones = response.data.data
                    this.form.zone = this.order.zone_id
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        get_tech_list: function(operations, date_, timestrip) {


            const data = {
                        'operations': operations,
                        'date_': date_,
                        'timestrip': timestrip
                    };


            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_get_technicians_list') , data, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    // Al nombre del tecnico le agregamos la cantidad de visitas asignadas
                    let tech = []
                    response.data.data.forEach(function(t) {
                        let txt = ""
                        if(t.assigned_visits > 0) {
                            if (t.assigned_visits == 1){
                                txt =  "(" + t.assigned_visits + " visita asignada)"
                            } else {
                                txt = "(" + t.assigned_visits + " visitas asignadas)"
                            }
                        }
                        tech.push({"text": t.text + " " + txt , "value": t.value, "name": t.text })
                    })

                    this.technicians_list = tech
                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        },
        submitContent: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };
            this.showOverlay = true;

            // Guardamos la dirección de la visita para agregarla a nuestra orden 
            this.form.client_address = this.order.client_address

            axios.post(this.$store.getters.getApiURL('api_visit_create') , this.form, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal({ 
                        icon: 'success',
                        title: 'Crear visita',
                        html: response.data.data ? "Se generó correctamente la visita!<br>" : "Se generó correctamente la visita!"}).then(() => {
                            if (response.data.data)
                            {
                                triggerSegmentUpdate(response.data.data)
                            }
                            this.$router.push({'name': store.state.defaultView})
                        });
                } else {
                    this.$swal({ 
                        icon: 'error',
                        title: 'Crear visita',
                        text: "Error al generar la visita: " + response.data.diagnostic});
                }
                this.showOverlay = false;
            })
            .catch(error => {
                this.$swal({ 
                        icon: 'error',
                        title: 'Crear visita',
                        text: "No se pudo generar la visita"});
                this.axios_error = error
                this.showOverlay = false;
            })
        },
        onSubmit: function(event) {
            // Envio el nombre del técnico por si no existe en la DB de agendatec.
            event.preventDefault()
            const val = this.technicians_list.filter(tech => tech.value == this.form.assigned_to)

            if (this.form.assigned_to != null)
            {
                if (val.length == 0)
                {
                    //this.$swal('No se pudo obtener el nombre del técnico. Por favor realice otra selección', '', 'error');
                    //return;
                    this.form.assigned_to = null
                } else {
                    this.form.assigned_name = val[0].name;
                }
            }

            if (this.order.pending_visits > 0)
            {
                this.$swal({
                    title: 'Confirmar operación',
                    text: 'Actualmente hay visitas pendientes para esta OT, desea continuar?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Confirmar',
                    denyButtonText: `No enviar`,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            this.submitContent()
                        } else if (result.isDenied) {
                            this.$swal('No se guardaron los cambios', '', 'info')
                        }
                })
            } else {
                this.submitContent()
            }
        },
        onReset: function(event){
            event.preventDefault()
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
              this.show = true
            })

        },
        loadOT: function() {
            console.log("Cargo data desde VisitForm para " + this.ot_type + this.ot_number)
            const data = {
                'ot_type': this.ot_type,
                'ot_number': this.ot_number
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_order_url') , data, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.order = response.data.data
                    this.form.priority = response.data.data.priority
                    this.form.tech_comments = response.data.data.detail
                    this.form.client_id = response.data.data.client_id
                    this.form.tag = response.data.data.tag
                    this.form.symptom_id = response.data.data.symptom_id
                    this.form.symptom_name = response.data.data.symptom_text
                    this.form.node_name = response.data.data.node_name
                    this.form.related_orders = response.data.data.related_orders ? response.data.data.related_orders.map(a => a.ot_number).join(",") : null
                    this.form.assigned_to = response.data.data.tech_id
                    // Obtengo los técnicos de esta operación
                    this.operation_assigned = this.order.ot_number.substring(0,3);
                    // Una vez obtenida la OT, cargo las zonas correspondientes
                    if (this.order.zone_id != null) this.getZones();
                    this.get_tech_list(this.operation_assigned, this.form.possibleDate, this.form.timestripe) 
                    // Una vez obtenida la OT, ya tengo la operacion de la misma entonces puedo cargar los turnos disponibles
                    this.getAvailableTimestripes();
                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        }
    }
}
</script>

<style scoped>
    #VisitFormContainer {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }
</style>
