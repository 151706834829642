<template>
    <div id="reschedulevisit">
        <b-modal :id="'RescheduleModal_' + modal_ref" size="xl" title="Reprogramar visita" scrollable>
            <h1>Reprogramar Visita{{ visit_id.length == 1 ? '' : 's'}}</h1>
            <div v-if="visit_id.length > 1">
                <b-alert variant="warning" show v-if="visit_id.length > 1">Se reagendará{{ visit_id.length == 1 ? '' : 'n'}} {{ visit_id.length }} visita{{ visit_id.length == 1 ? '' : 's'}}</b-alert>
                <p>Al reprogramar las visitas, se cerrarán las visitas actuales, y se crearán las nuevas en la fecha seleccionada</p>
                <p>En el caso que se necesite cambiar la franja horaria de las visitas, editar las mismas y seleccionar una nueva franja.</p>
            </div>
            <div v-if="visit_id.length === undefined || visit_id.length == 1">
                <p>Al reprogramar la visita, se cerrará la visita actual, y se creará una nueva en la fecha seleccionada</p>
                <p>En el caso que se necesite cambiar la franja horaria de una visita, editar la misma y seleccionar una nueva franja.</p>
            </div>
            <!-- <p>Editando visita #{{ visit_id }}</p> -->
            <div v-if="technician_id">
                <h2>Opciones</h2>
                <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                    v-model="form.checked"
                    id="checkboxes-4"
                    :aria-describedby="ariaDescribedby"
                    >
                    <!-- <b-form-checkbox value="notify_client" switch disabled>Enviar notificación al cliente</b-form-checkbox> -->
                    <!-- <b-form-checkbox value="public_link" switch>Generar link público</b-form-checkbox> -->
                    <b-form-checkbox value="unset_tech" switch>Desasignar técnico</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-form-group>
            </div>
            <b-form-group id="input-group-31" label="Nueva fecha:" label-for="input-31">
            <b-form-datepicker
                id="input-31" 
                v-model="form.new_date"
                :min="addDays(new Date(), 1)"
                v-bind="calendar_labels || {}"
                :locale="calendar_locale"
                @input="getAvailableTimestripes"
                :dropup="true"
                required>
            </b-form-datepicker>
            </b-form-group>
            <b-form-group id="input-group-4" label="Franja horaria:" label-for="input-4">
                <b-form-select
                id="input-4"
                v-model="form.new_timestripe"
                :options="timestripes"
                required
                ></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-50" label="Motivo:" label-for="input-50">
                <b-form-select
                id="input-50"
                name="input-50"
                v-model="form.new_status"
                :options="reasons"
                :required="true"
                ></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-21" label="Notas*:" label-for="input-21">
                <b-form-textarea
                    id="textarea"
                    v-model="form.reschedule_comments"
                    placeholder="Escriba su texto..."
                    rows="3"
                    max-rows="6"
                    :required="true"
                ></b-form-textarea>
            </b-form-group>
            <b>Recuerde que las visitas agendadas representan un compromiso con los clientes</b>
            <template #modal-footer="{ close }">
                <!-- Emulate built in modal header close button action -->
                <b-button size="sm" variant="warning" @click="nextTickSend()">
                    Reagendar Visita
                </b-button>
                <b-button size="sm" variant="primary" @click="close()">
                    Cerrar
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

    import axios from 'axios'
    import store from '../store'

    export default {
    name: "RescheduleVisitModal",
    //api_visit_reschedule
    props: { 
        'modal_ref': {}, 
        'visit_id': {}, 
        'visit_timestripes': {}, 
        'selected_timestripe': {}, 
        'technician_id': {},
        'behavior': {
            type: String,
            default: 'goback'
        }
    },
    store: store,
    data: function() {
        return {
            timestripes: null,
            reasons: null,
            calendar_locale: "es-AR",
            calendar_labels: {
                labelPrevDecade: 'Década anterior',
                labelPrevYear: 'Año anterior',
                labelPrevMonth: 'Mes anterior',
                labelCurrentMonth: 'Mes actual',
                labelNextMonth: 'Siguiente mes',
                labelNextYear: 'Siguiente año',
                labelNextDecade: 'Siguiente década',
                labelToday: 'Hoy',
                labelSelected: 'Fecha seleccionada',
                labelNoDateSelected: 'Sin fecha seleccionada',
                labelCalendar: 'Calendario',
                labelNav: 'Navegación',
                labelHelp: 'Se puede navegar el calendario con las flechas del teclado'
            },
            form: {
                visit_id: null,
                new_date: null,
                checked: [ 'unset_tech', 'public_link' ],
                new_status: null,
                new_timestripe: null,
                reschedule_comments: null
            }
        }
    },
    created: function() {
        this.form.visit_id = this.visit_id
        this.form.new_timestripe = this.selected_timestripe
        this.form.new_date = new Date();
        if (this.visit_timestripes === undefined)
        {
            this.getAvailableTimestripes()
        } else {
            this.timestripes = this.visit_timestripes;
        }
        this.getReasons();
    },
    mounted: function() {
    },
    methods: {
        getReasons: function() {
            const payload = {
                'type': 'and',
                'show_cancel': true,
                'show_finish': false
            }
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_visits_reasons_to_finish'), payload, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.reasons = response.data.data
                    // if (this.reasons.length > 0)
                    // {
                    //     this.form.new_status = this.reasons[0].value;
                    // }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        getAvailableTimestripes: function() {
            //api_visit_get_timestripes_by_visit_id
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'visit_id': this.visit_id,
                'date_selected': this.form.new_date
            }

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_visit_get_timestripes_by_visit_id') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.timestripes = response.data.data
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        addDays: function (date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },
        nextTickSend: function() {
            let self = this;

            // Validación de estado
            if (this.form.new_status == null) 
            {
                this.$swal({ 
                        icon: 'error',
                        title: 'Reprogramar Visita',
                        text: "Debe seleccionar un motivo de la lista"});
                return;
            }

            // Validación de comentario
            if (this.form.reschedule_comments == null) 
            {
                this.$swal({ 
                        icon: 'error',
                        title: 'Reprogramar Visita',
                        text: "Debe completar las notas de la reprogramación"});
                return;
            }

            // Espero al nextTick porque tarda en actualizar los campos. BUG: Estaba saliendo con el ID de franja incorrecto debido a la demora
            this.$nextTick()
            
            .then(function () {
                self.rescheduleSend();
            });
        },
        rescheduleSend: function() {
            
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            axios.post(this.$store.getters.getApiURL('api_visit_reschedule') , this.form, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal({ 
                        icon: 'success',
                        title: 'Reprogramar visita',
                        html: response.data.data ? "Se reprogramó correctamente<br> #" + response.data.data.new_id : "Se reprogramó correctamente"}).then(() => {
                            if (this.behavior == "goback")
                            {
                                this.$router.push({'name': store.state.defaultView})
                            } else if (this.behavior == "emit")
                            {
                                this.$emit("success", true)
                            }
                            this.$bvModal.hide('RescheduleModal_' + this.modal_ref)
                        });
                } else {
                    this.$swal({ 
                        icon: 'error',
                        title: 'Reprogramar visita',
                        text: "Error al reprogramar la visita: " + response.data.diagnostic});
                }
            })
            .catch(error => {
                if(error.response.status === 403){
                    this.$swal({
                        icon: 'error',
                        title: 'Reprogramar visita',
                        text: "No posee permisos para reprogramar visitas"
                    });
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Reprogramar visita',
                        text: "No se pudo reprogramar la visita"
                    });
                }

                this.axios_error = error
            });
        },
    },
    watch: {
        visit_id: function(newValue) {
            this.form.visit_id = newValue
        }
    }
    }
</script>

<style scoped>

</style>
