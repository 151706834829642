<template>
    <div id="visitview">
        <b-container fluid>
            <b-row>
                <b-col cols="12" md="6">
                    <JefeVisitInfo 
                        :visit_id="visit_id" 
                        :edit="getPermissionFields"
                        @set-buttons="setButtons"
                        @set-ot-data="setOTData"
                        @set-timestripes="setTimestripes"
                        @set-status="setStatus"
                        @set-comment="setComment"
                        @set-tech-id="setTechID"
                        @set-signature="setSignature"
                        @set-on-the-way="setOnTheWay"
                        @set-new-tech-id="setNewTechID">
                    </JefeVisitInfo>
                    <!-- <FinishVisitModal 
                        :visit_id="visit_id" 
                        :default_status="visit_status"
                        :extra_comments="extra_comment"
                        :show_every_reason="true"></FinishVisitModal> -->
                    <RescheduleVisitModal v-if="selected_timestripe"
                        :visit_id="visit_id"
                        :modal_ref="visit_id"
                        :technician_id="visit_tech"
                        :visit_timestripes="timestripes"
                        :selected_timestripe="selected_timestripe">
                    </RescheduleVisitModal>
                    <div v-if="showButtons && (visit_status === 1 || visit_status === 14 || visit_status === 17)" class="d-flex d-print-none mt-2 justify-content-center">
                        <!-- <b-button @click="finishVisit" class="mb-2" variant="warning">Cambiar estado</b-button> -->
                        <b-button v-if="visit_status === 1 || visit_status === 14" @click="rescheduleVisit" class="ml-2 mr-2 mb-2" variant="danger">Reprogramar visita</b-button>
                        <b-button @click="updateVisit" class="mb-2" variant="success">Guardar cambios</b-button>
                    </div>
                </b-col>
                <b-col v-if="order" cols="12" md="6">
                    <h4 class="mt-4">Orden {{ order.ot_number }} | {{ order.ot_type_friendly }}</h4>
                    <OrderInfo :order_data="order" :autoload="false"></OrderInfo>
                    <div v-if="visit_signature" >
                        <img class="mb-2" :src="visit_signature" width="150px" height="150px" align="right">
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import JefeVisitInfo from '../components/JefeVisitInfo.vue'
    import OrderInfo from "../components/OrderInfo.vue"
    // import FinishVisitModal from "../components/FinishVisitModal.vue"
    import RescheduleVisitModal from "../components/RescheduleVisitModal.vue"
    import bus from "../bus"
    import axios from 'axios'
    import store from "../store"

    export default {
    name: "visitview",
    props: [ ],
    data() {
        return {
            visit_id: null,
            visit_tech: null,
            visit_signature: null,
            ot_number: null,
            ot_type: null,
            showButtons: false,
            order: null,
            timestripes: null,
            selected_timestripe: null,
            visit_status: null,
            extra_comment: null,
            visit_on_the_way: null,
            new_tech_for_visit: null,
        }
    },
    components: {
        JefeVisitInfo,
        OrderInfo,
        // FinishVisitModal,
        RescheduleVisitModal
    },
    methods: {
        setTechID: function(event) {
            this.visit_tech = event;
        },
        setStatus: function(event) {
            this.visit_status = event;
        },
        setComment: function(event) {
            this.extra_comment = event;
        },
        setSignature: function(event) {
            this.visit_signature = event;
        },
        setOnTheWay: function(event) {
            this.visit_on_the_way = event;
        },
        setNewTechID: function(event) {
            this.new_tech_for_visit = event;
        },
        setButtons: function() {
            const userPermissions = store.state.permissions;
            const neededPermissions = ['can_assign_visit', 'can_cancel_visit', 'can_create_visit'];

            // Devuelve 'true' si el usuario tiene todos los permisos necesarios. Si no, devuelve 'false'
            const userHasAllPermissions = neededPermissions.every(permission => {
                return userPermissions.includes(permission);
            });

            this.showButtons = userHasAllPermissions;
        },
        setOTData: function(ot_type, ot_number) {
            //Evento emitido por VisitInfo
            this.ot_type = ot_type
            this.ot_number = ot_number
            this.getOTData()
        },
        setTimestripes: function(actual, data) {
            this.selected_timestripe = actual
            this.timestripes = data
        },
        // finishVisit: function() {
        //     this.$bvModal.show("FinishModal_" + this.$route.params.visit_id)
        // },
        updateVisit: function() {
            if(this.visit_on_the_way && this.visit_tech !== null && this.visit_tech !== parseInt(this.new_tech_for_visit)) {
                this.$swal({
                    title: 'Atención',
                    text: 'No se puede desasignar ni cambiar el tecnico de  una visita en camino. Si es necesario, cierre la visita.',
                    icon: 'warning',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'Entendido',
                });
            }else{
                bus.$emit('updateVisit')
            }
            // bus.$emit('updateVisit')
        },
        rescheduleVisit: function() {
            if(this.visit_on_the_way) {
                this.$swal({
                    title: 'Atención',
                    text: 'No se puede reprogramar una visita en camino. Si es necesario, cierre la visita.',
                    icon: 'warning',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'Entendido',
                });
            }else{
                this.$bvModal.show("RescheduleModal_" + this.$route.params.visit_id)
            }
        },
        getOTData: function() {
            const data = {
                'ot_type': this.ot_type,
                'ot_number': this.ot_number
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_order_url') , data, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.order = response.data.data
                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        }
    },
    created: function() {
        this.visit_id = parseInt(this.$route.params.visit_id)
    },
    watch: {
        '$route' () {
            this.visit_id = parseInt(this.$route.params.visit_id)
        },
    }, 
    computed: {
        getPermissionFields: function() {
            if (this.$route.params.mode == "calendario")
            {
                return []
            }
            else if (this.$route.params.mode == "jefetec" || this.$route.params.mode === "default")
            {
                return [ 'tech_comments', 'tech', 'status' ]
            } else {
                return []
            }
        },
    }
    }
</script>

<style lang="scss" scoped>

    @import '../assets/custom-theme.scss';

    #visitview {
        overflow-y: scroll;
        flex-grow: 1;
        height: 100%;
    }

    @media print {
        #visitview {
        height: 100%;
        }

        p {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #eeeeee;
        }
    }

</style>
