<template>
    <div id="indextecnico">
        <b-container>
            <b-row>
                <b-col cols="12">
                    <div v-if="!visits && this.user">
                        <h4 v-if="!finishedLoading">Cargando visitas para {{ this.user.fullName }}</h4>
                        <h4 v-else>{{ errorMsg }}</h4>
                    </div>
                    <div v-if="visits">
                        <div class="text-center mt-4" v-if="visits && visits.assigned.visits.length == 0">
                            <h3>No posee visitas asignadas para el día de hoy. 
                                <span v-if="visits.timestripe.visits.length > 0">Puede tomar otras visitas disponibles en el día</span>
                            </h3>
                        </div>
                        <div v-for="visit in visits" :key="visit.name" class="visit-item">
                            <h4 v-if="visit.visits.length > 15" style="margin-bottom:15px;margin-top:15px"  >Usted tiene {{visit.visits.length}} visitas pendientes. Se mostrarán las 15 más antiguas.</h4>
                            <transition-group name="fade">
                            <div v-for="visit_itm in visit.visits.slice(0,15)" :key="visit_itm.id" class="visit-item">
                                <TecVisitInfo :visit_id="visit_itm.id" :edit="[]" :onTheWay="visit_itm.on_the_way"></TecVisitInfo>
                                <FinishVisitModal
                                    v-if="subsidiary_capabilities" 
                                    :visit_id="visit_itm.id" 
                                    :show_every_reason="false"
                                    :redirect="false"
                                    @kill-visit="killVisit"></FinishVisitModal>
                                <DetachVisitModal
                                    v-if="subsidiary_capabilities" 
                                    :visit_id="visit_itm.id" 
                                    :show_every_reason="false"
                                    :redirect="false"
                                    @name="postDetachVisit"
                                    @kill-visit="killVisit"></DetachVisitModal>
                                <div class="buttonbar" style="justify-content:center;align-items:center;">
                                    <b-button v-if="!visit_itm.on_the_way && visit_itm.assigned && subsidiaryIsAllowed() && !visit_itm.setting_on_the_way" @click="setVisitOnTheWay(visit_itm.id)" :variant="visits.assigned.tech_on_the_way ? 'secondary': 'info'" class="mt-2 mr-2">Voy en camino</b-button>
                                    <b-spinner variant="primary" class="mr-2 mt-1" v-if="visit_itm.setting_on_the_way"></b-spinner>
                                    <b-button v-if="visit_itm.assigned" @click="detachVisit(visit_itm.id, visit_itm.on_the_way, visit_itm.delayed)" :variant="visit_itm.on_the_way ? 'secondary' : 'warning'" class="mt-2 mr-2" >Desasignarme esta visita</b-button>
                                    <b-button v-if="!visit_itm.assigned" @click="attachVisit(visit_itm.id)" variant="success" class="mt-2 mr-2">Asignarme esta visita</b-button>
                                    <b-button v-if="visit_itm.assigned" @click="finishVisit(visit_itm.id)" variant="success" class="mt-2 mr-2">Cerrar visita</b-button>
                                </div>
                                <hr>
                            </div>
                            </transition-group>
                        </div>
                    </div>
                </b-col>
            </b-row> 
        </b-container>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import { getUserProfile } from '../common'
    import store from '../store'
    import TecVisitInfo from "../components/TecVisitInfo.vue"
    import FinishVisitModal from "../components/FinishVisitModal.vue"
    import DetachVisitModal from "../components/DetachVisitModal.vue"

    export default {
    name: "IndexTecnico",
    store: store,
    components: {
        TecVisitInfo,
        FinishVisitModal,
        DetachVisitModal
    },
    data() {
        return {
            user: null,
            visits: null,
            subsidiary_capabilities: null,
            allowedSubsidiaries: [],
            settingOnTheWay: false,
            finishedLoading: false,
            errorMsg: "Hubo un error cargando las visitas",
        }
    },
    created() {
        getUserProfile(store.state.username, store.state.token).then( response => {
            this.user = response
            this.getTechVisits()
        });

        this.getAllowedSubsidiaries();
       
    },
    methods: {
        detachVisit: function(visit_id, on_the_way, delayed) {
            if(on_the_way) {
                this.$swal({
                    icon: 'warning',
                    title: 'No permitido',
                    text: 'No se puede desasignar una visita en camino. Si no podes realizar la visita, por favor cancelala.',
                    confirmButtonText: 'Entendido',
                });
            } else if(delayed){
                this.$swal({
                    icon: 'warning',
                    title: 'No permitido',
                    text: 'No se puede desasignar una visita con cierre demorado.',
                    confirmButtonText: 'Entendido',
                }); 
            }else{
                this.$bvModal.show("DetachModal_" + visit_id.toString())
            }
        },

        getSubsidiaryCapabilities: function() {
            const data = {
                'visit_id': this.visits.assigned.visits[0].id
            };

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_subsidiary_capabilities') , data, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.subsidiary_capabilities = response.data.data
                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        },
        getAllowedSubsidiaries: function() {
            const data = {}

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            axios.post(this.$store.getters.getApiURL('api_allowed_subsidiaries'), data, config)
                .then(response => {
                    this.allowedSubsidiaries = response.data.data;
                })
                .catch(() => {
                    this.allowedSubsidiaries = [];
                });

        },
        subsidiaryIsAllowed() {
            return this.allowedSubsidiaries.includes(this.user.subsidiaries[0].subsidiary_id);
        },
        attachVisit: function(visit_id) {
            this.$swal({
                    title: 'Confirmar operación',
                    text: '¿Desea asignarse la visita?',
                    icon: 'question',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Confirmar',
                    denyButtonText: `Cancelar`,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            this.postAttachVisit(visit_id);
                        }
                })
        }, 
        setVisitOnTheWay: function(visit_id) {
            if(this.visits.assigned.tech_on_the_way) {
                this.$swal({
                        title: 'No permitido',
                        text: 'Ya vas en camino para otra visita.',
                        icon: 'warning',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'Entendido',
                });
            } else {
                this.$swal({
                        title: 'Atención',
                        text: 'Se enviara un mensaje al cliente notificando que vas en camino',
                        icon: 'warning',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'Confirmar',
                        denyButtonText: `Cancelar`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.postSetVisitOnTheWay(visit_id, true);
                            }
                    })
            }
        }, 
        getTechVisits: function() {
            console.log("Cargo visitas de " + store.state.username)
            const data = {
                'tech_id': this.user.tech_id,
                'include_finished': false
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visits_by_tech_id') , data, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.visits = response.data.data
                    this.visits.assigned.visits.forEach(visit => {
                        visit["setting_on_the_way"] = false;

                    });
                    this.getSubsidiaryCapabilities() 
                }
                else {
                    this.$swal({ 
                    icon: 'error',
                    title: 'Obtener visitas',
                    text: `No se pudo obtener visitas: ${response.data.diagnostic}`
                    });
                }
            })
            .catch(error => {
                this.finishedLoading = true;
                this.errorMsg = error.response.data.diagnostic;
            })
        },
        postAttachVisit: function(visit_id) {
            console.log("Cargo visitas de " + store.state.username)
            const data = {
                'visit_id': visit_id
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_attach') , data, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal({ 
                    icon: 'success',
                    title: 'Asignar Visita',
                    text: 'Visita asignada correctamente'
                    });
                    this.getTechVisits();
                }
                else {
                    this.$swal({ 
                    icon: 'error',
                    title: 'Asignar Visita',
                    text: `No se pudo asignar: ${response.data.diagnostic}`
                    });
                    this.getTechVisits();
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        },
        postDetachVisit: function(args) {

            let visit_id = args.visit_id;
            let comments = args.comments

            const data = {
                'visit_id': visit_id,
                'notes': comments 
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_detach') , data, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal({ 
                    icon: 'success',
                    title: 'Desasignar Visita',
                    text: 'Visita desasignada correctamente'
                    });
                    this.getTechVisits();
                }
                else {
                    this.$swal({ 
                    icon: 'error',
                    title: 'Desasignar Visita',
                    text: `No se pudo desasignar: ${response.data.diagnostic}`
                    });
                    this.getTechVisits();
                }
            })
            .catch(error => {
                this.$swal({
                    icon: 'error',
                    title: 'Hubo un error',
                    text: error.response.data.diagnostic,
                });
                this.axios_error = error
            })
        },
        finishVisit: function(visit_id) {
            this.$bvModal.show("FinishModal_" + visit_id.toString())
        },
        postSetVisitOnTheWay: function(visit_id, onTheirWay) {
            const index = this.visits.assigned.visits.findIndex(visit => visit.id == visit_id);
            Vue.set(this.visits.assigned.visits, index, { ...this.visits.assigned.visits[index], setting_on_the_way: true });

            const data = {
                'visit_id': visit_id,
                'on_the_way': onTheirWay,
                'send_notification': true,
            };

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` },
                'timeout': parseInt(process.env.VUE_APP_MILLISECONDS_TIMEOUT_FOR_SET_ON_THE_WAY),
            };

            this.axios_error = "";

            axios.post(this.$store.getters.getApiURL('api_visit_set_on_the_way'), data, config)
                .then(response => {
                    if(!response.data.error) {
                        // Cambiamos el campo tech_on_their_way de la visita correspondiente para reflejar los cambios
                        const foundIndex = this.visits.assigned.visits.findIndex(visit => visit.id === visit_id);
                        this.visits.assigned.visits[foundIndex].on_the_way = onTheirWay;
                        this.visits.assigned['tech_on_the_way'] = true;
                        const detail = response.data.data.detail;
                        let icon = "success"
                        let title = "Exito"
                        let text = "Vas en camino";
                        if(detail) {
                            icon = "warning",
                            title = "Atención",
                            text = `${text} (${detail})`;
                        }
                        this.$swal({
                            icon: icon,
                            title: title,
                            text: text,
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Hubo un error',
                            text: response.data.diagnostic,
                        });
                    }
                })
                .catch(error => {
                    if (error.code === 'ECONNABORTED') {
                        this.$swal({
                            icon: 'error',
                            title: 'Problemas de conexión',
                            text: 'Parece que estás experimentando problemas de conexión. Por favor, recargá la página y fijate si la visita se marcó como "En camino".',
                            confirmButtonText: 'Recargar',
                        })
                        .then(() => this.$router.go());
                    } else{
                        this.$swal({
                            icon: 'error',
                            title: 'Hubo un error',
                            text: error.response.data.diagnostic,
                        });
                    }
                })
                .finally(() => {
                    let visitIndex = this.visits.assigned.visits.findIndex(visit => visit.id == visit_id);
                    Vue.set(this.visits.assigned.visits, visitIndex, { ...this.visits.assigned.visits[visitIndex], setting_on_the_way: false });
                });
        },
        killVisit: function(visit_id) {

            this.getTechVisits();

            console.log("Quitando visita " + visit_id)
            // Una vez que recibo este emit del modal, elimino el id de la lista de visitas para que el v-for lo saque del render.
            // A nivel API ya se cerró esta visita.
            var index = this.visits.assigned.visits.find(element => element.id == visit_id);

            if (index !== -1) {
                this.visits.assigned.visits.splice(index, 1);
            } else {
                // Muestro un mensaje de error solicitando que refresquen esta página...
                this.$swal({ 
                    icon: 'error',
                    title: 'Actualizar visitas',
                    text: 'No se pudo actualizar listado. Por favor refresque esta página'
                    });
            }
        }
    }
    }
</script>

<style lang="scss" scoped>
    #indextecnico {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0
    }
</style>

